import {forwardRef, InputHTMLAttributes, useEffect, useImperativeHandle, useRef} from 'react';

export default forwardRef(function TextInput(
  {type = 'text', className = '', isFocused = false, ...props}: InputHTMLAttributes<HTMLInputElement> & {
    isFocused?: boolean
  },
  ref
) {
  const localRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    focus: () => localRef.current?.focus(),
  }));

  useEffect(() => {
    if (isFocused) {
      localRef.current?.focus();
    }
  }, []);

  return (
    <input
      {...props}
      type={type}
      className={
        'border-gray-300 focus:border-slate-500 focus:ring-slate-500 rounded-md shadow-sm ' +
        className
      }
      ref={localRef}
    />
  );
});
